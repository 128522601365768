import React, { useState, Fragment } from "react";
import { useSpring, animated } from "react-spring";

// Components
import Menu from "./Menu";

// Dependencies
import { Link } from "react-router-dom";

// Styles
import styled from "styled-components";
import { themeColors, fontFamily, fontWeight } from "../Utilities";
import { Logo } from "../Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faBars,
  faSignOut,
} from "@fortawesome/pro-regular-svg-icons";
import {
  faFirstAid,
  faChevronLeft,
  faLayerGroup,
} from "@fortawesome/pro-solid-svg-icons";

import { gql, useMutation } from '@apollo/client'

const LOGOUT = gql`
  mutation logout {
    logout {
      user {
        id
      }
    }
  }
`;

export default function Header(props) {
  const { onCollectionPage, user, client } = props;
  const [logout, { data }] = useMutation(LOGOUT)

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }
  const menuAnimation = useSpring({
    transform: isMenuOpen ? `translate3d(0, 0, 0)` : `translate3d(100%, 0, 0)`,
  });
  const menuOpacity = useSpring({
    opacity: isMenuOpen ? 0.5 : 0,
    display: isMenuOpen ? `block` : `none`,
  });

  function renderBackNav() {
    if (onCollectionPage) {
      return (
        <div className="back-icon">
          <FontAwesomeIcon icon={faChevronLeft} className="icon" />
        </div>
      );
    } else {
      return (
        <div className="back-icon">
          <span className="logo-medzcool">MEDZCOOL</span>
        </div>
      );
    }
  }

  return (
    <HeaderContainer>
      <HeaderInnerContainer>
        <HeaderLeft>
          <Logo>
            <div className="menu-item">
              <Link to="/">CLINIC</Link>
            </div>
          </Logo>
        </HeaderLeft>
        <HeaderRight user={user}>
          <Fragment>
            <Menu
              style={menuAnimation}
              toggleMenu={toggleMenu}
              opacity={menuOpacity}
              logout={logout}
              client={client}
              user={user}
            />
          </Fragment>
          <div className="menu-item" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <FontAwesomeIcon icon={faBars} size="lg" />
          </div>
        </HeaderRight>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  height: 50px;
  background-color: ${themeColors.dark};
  a {
    text-decoration: none;
    color: white;
  }
  @media (max-width: 425px) {
    display: none;
  }
`;
const HeaderInnerContainer = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  max-width: 1300px;
  margin: auto;
  padding: 0 15px;
  @media (max-width: 425px) {
    grid-template-columns: 1fr 2fr;
  }
`;
const HeaderRight = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.user ? `auto auto auto auto` : `auto auto auto`};
  color: white;
  justify-self: end;
  align-items: center;
  font-family: ${fontFamily.sansSerif};
  &.non-auth {
    grid-template-columns: auto auto;
  }
  .menu-item {
    cursor: pointer;
    margin-left: 40px;
    a {
      text-decoration: none;
      color: white;
    }
    &.auth-btn {
      margin-left: 30px;
    }
  }
  @media (max-width: 768px) {
    .menu-item {
      &.desktop {
        display: none;
      }
    }
  }
`;
const HeaderLeft = styled.div`
  color: white;
  .back-icon {
    .icon {
      margin-right: 8px;
    }
    .logo-medzcool {
      position: relative;
      top: -2px;
    }
  }
  .logo-icon {
    height: 30px;
    display: none;
  }
  .logo-text {
    a {
      text-decoration: none;
      color: white;
    }
  }
  .menu-item {
    font-family: ${fontFamily.sansSerif};
    cursor: pointer;
    a {
      text-decoration: none;
      color: white;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 768px) {
    .back-icon {
      .logo-medzcool {
        display: none;
      }
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  @media (max-width: 425px) {
    .logo-icon {
      display: block;
    }
    .logo-text {
      display: none;
    }
  }
`;

import React from "react";

// Dependencies
import MonthlyCyclesTutorial from "../Notifications/MonthlyCyclesTutorial";
import StatisticsTutorial from "../Notifications/StatisticsTutorial";

//Components
import EncounterCard from "../Components/EncounterCard/EncounterCard";

// Styles
import styled from "styled-components";
import { fontWeight, fontFamily, grayscale, blue } from "../Cytoplasm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { Fragment } from "react/cjs/react.production.min";

export default function WaitingRoom(props) {
  const {
    clinicWelcome,
    monthlyCyclesTutorial,
    setClinicWelcome,
    pendingEncounters,
    priorEncounters,
    archivedEncounters,
    getPriorEncounters,
    fetchArchivedEncounters,
    getPendingEncounters,
    archiveTutorial,
    setArchiveTutorial,
    statisticsTutorial,
    followupCycleEncounters,
    setFollowupCycleEncounters,
    user,
    followupEncounters,
    setFollowupEncounters,
    setMonthlyCyclesTutorial,
  } = props;

  if (!pendingEncounters) {
    getPendingEncounters();
    return null;
  }
  let featuredEncounters = pendingEncounters.filter(
    (encounter) => encounter.thumbnail
  );

  if (featuredEncounters.length > 3) {
    featuredEncounters = featuredEncounters.slice(0, 3);
  }

  let filteredPendingEncounters;
  if (featuredEncounters) {
    const prefilteredPendingEncounters = pendingEncounters.filter(
      (pending_encounter) =>
        featuredEncounters.filter((featured_encounter) => {
          return featured_encounter.id == pending_encounter.id;
        }).length == 0
    );
    filteredPendingEncounters = prefilteredPendingEncounters.sort(
      () => Math.random() - 0.5
    );
  } else {
    filteredPendingEncounters = pendingEncounters;
  }

  // if (pendingEncounters.length < 1) {
  //   return (
  //     // <>
  //     //   <p>You have no pending patient encounters</p>
  //     //   <MonthlyCyclesTutorial
  //     //     user={user}
  //     //     dismissed={monthlyCyclesTutorial}
  //     //     setMonthlyCyclesTutorial={setMonthlyCyclesTutorial}
  //     //   />

  //     // </>
  //   );
  // }

  return (
    <Container>
      <FeaturedEncounterContainer>
        {featuredEncounters &&
          featuredEncounters.map((featuredEncounter) => {
            return (
              <FeaturedEncounter
                key={featuredEncounter.id}
                onClick={() =>
                  props.history.push(`/encounter/${featuredEncounter.hash_id}`)
                }>
                <img src={featuredEncounter.thumbnail} alt="" />
                <h4>{featuredEncounter.description}</h4>
                <div className="chevron">
                  <FontAwesomeIcon icon={faChevronRight} />
                </div>
              </FeaturedEncounter>
            );
          })}
      </FeaturedEncounterContainer>

      {filteredPendingEncounters.map((encounter) => {
        return (
          <EncounterCard key={encounter.id} encounter={encounter} {...props} />
        );
      })}

      {pendingEncounters.length < 1 && (
        <>
          <p>You have no pending patient encounters</p>
          <MonthlyCyclesTutorial
            user={user}
            dismissed={monthlyCyclesTutorial}
            setMonthlyCyclesTutorial={setMonthlyCyclesTutorial}
          />
        </>
      )}

      <div className="followup-container">
        {followupEncounters && followupEncounters.length > 0 && (
          <h3>Follow-Ups</h3>
        )}
        {followupEncounters &&
          followupEncounters.map((encounter) => {
            return (
              <EncounterCard
                followup
                key={encounter.id}
                encounter={encounter}
                {...props}
              />
            );
          })}
      </div>

      <StatisticsTutorial user={user} dismissed={statisticsTutorial} />
    </Container>
  );
}
const Container = styled.div`
  .followup-container {
    margin-top: 50px;
    .followup-icon {
      color: ${blue.bright};
    }
  }
`;
const FeaturedEncounterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
  margin-bottom: 15px;
  margin-top: 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 5px;
    margin-bottom: 5px;
  }
`;
const FeaturedEncounter = styled.div`
  cursor: pointer;
  background: ${grayscale.lightGray};
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  .chevron {
    color: ${blue.bright};
    display: none;
  }
  img {
    max-width: 100%;
    height: 150px;
    object-fit: cover;
  }
  h4 {
    margin-top: 5px;
  }
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 2fr auto;
    grid-gap: 15px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 10px 20px 10px 10px;
    h4 {
      margin-bottom: 0;
    }
    .chevron {
      display: block;
      align-self: center;
    }
    img {
      height: 130px;
    }
  }
  @media (max-width: 425px) {
    img {
      height: 75px;
    }
  }
`;
const EncounterCardStyle = styled.div`
  cursor: pointer;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 25px;
  align-items: center;
  .chevron {
    color: ${(props) =>
      props.encounterType == "prior encounter" ? `white` : `${blue.bright}`};
  }
  .date {
    font-size: 12px;
    font-weight: ${fontWeight.light};
  }
  background: ${(props) =>
    props.encounterType == "prior encounter"
      ? `${grayscale.darkDark}`
      : `${grayscale.lightGray}`};
  color: ${(props) =>
    props.encounterType == "prior encounter" ? `white` : `black`};
`;

import React from "react";

export default function ServerError(props) {
  return (
      <div style={{ textAlign: "center", padding: "15px" }}>
        <h1 style={{ marginBottom: 0 }}>Uh oh!</h1>
        <p>
          Sorry, it seems that our servers are down for maintenance. Please
          check back later.
        </p>
      </div>
  );
}

import React, { useState, useEffect } from "react";

// Dependencies
import moment from "moment";

//Components

// Styles
import styled from "styled-components";
import { fontWeight, grayscale, blue, green, red } from "../../Cytoplasm";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faRepeat,
  faCheck,
  faTimes,
  faPager,
} from "@fortawesome/pro-solid-svg-icons";

export default function EncounterCard(props) {
  const { encounter, followup } = props;

  const [answerIcon, setAnswerIcon] = useState(null);
  useEffect(() => {
    if (encounter.encounterAnswer) {
      if (encounter.encounterAnswer == "correct") {
        setAnswerIcon(
          <FontAwesomeIcon
            style={{ color: `${green.bright}` }}
            icon={faCheck}
          />
        );
      } else if (encounter.encounterAnswer == "consult") {
        setAnswerIcon(
          <FontAwesomeIcon
            style={{ color: `lightgray` }}
            icon={faPager}
            size="lg"
          />
        );
      } else if (encounter.encounterAnswer == "incorrect") {
        setAnswerIcon(
          <FontAwesomeIcon style={{ color: `${red.bright}` }} icon={faTimes} />
        );
      }
    }
  }, []);

  return (
    <EncounterCardContainer
      key={encounter.id}
      indicatorIcon={followup || encounter.encounterAnswer}
      priorEncounter={encounter.encounterAnswer}
      onClick={() => props.history.push(`/encounter/${encounter.hash_id}`)}>
      <div className="encounter-info-container">
        <div className="encounter-title">{encounter.title}</div>
        <div className="encounter-description">{encounter.description}</div>

        {encounter.date && (
          <div className="encounter-date">
            Completed {moment(encounter.date).fromNow()}
          </div>
        )}
      </div>

      {encounter.encounterAnswer && answerIcon}

      {followup && (
        <div className="followup-icon">
          <FontAwesomeIcon icon={faRepeat} />
        </div>
      )}

      <div className="chevron">
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    </EncounterCardContainer>
  );
}

const EncounterCardContainer = styled.div`
  cursor: pointer;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: ${(props) =>
    props.indicatorIcon ? `1fr auto auto` : `1fr auto`};
  grid-gap: 25px;
  align-items: center;

  .encounter-info-container {
    .encounter-title {
      font-size: 18px;
      font-weight: ${fontWeight.bold};
      margin-bottom: 5px;
    }
    .encounter-date {
      font-size: 12px;
      font-weight: ${fontWeight.light};
      margin-top: 3px;
    }
  }

  .chevron {
    color: ${(props) => (props.priorEncounter ? `white` : `${blue.bright}`)};
  }

  background: ${(props) =>
    props.priorEncounter ? `${grayscale.darkDark}` : `${grayscale.lightGray}`};
  color: ${(props) => (props.priorEncounter ? `white` : `black`)};
`;

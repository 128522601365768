import React, { useState } from "react";
import { url } from "../settings";

// Dependencies

//Components

// Styles
import styled from "styled-components";
import { fontFamily } from "../Typography";
import { grayscale, blue } from "../Utilities";
import { fontWeight } from "../Cytoplasm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandHoldingMedical } from "@fortawesome/pro-duotone-svg-icons";

export default function ClinicWelcomeNotification(props) {
  const { user, dismissed, setClinicWelcome } = props;

  function dismissNotification() {
    setVisible(false);
    setClinicWelcome(true);
    return fetch(`${url}/rounds/dismiss-notification/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
        notification: "clinic_welcome_notification",
      }),
    }).then((response) => {
      return response;
    });
  }

  const [visible, setVisible] = useState(true);

  if (!visible) return null;
  if (dismissed) return null;

  return (
    <TutorialContainer>
      <FontAwesomeIcon icon={faHandHoldingMedical} size="6x" />
      <div className="big-text">Welcome To Your Clinic</div>
      <p className="text">
        Every week, new patient encounters will present to your
        waiting room. <i>Encounters</i> are multiple-choice questions,
        where you will have to analyze medical information to solve a clinical
        scenario.
      </p>
      <div className="confirm" onClick={() => dismissNotification()}>
        GET STARTED
      </div>
    </TutorialContainer>
  );
}
const TutorialContainer = styled.div`
  padding: 25px;
  background-color: ${grayscale.lightGray};
  text-align: center;
  .text {
    font-size: 18px;
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  }
  .big-text {
    font-size: 28px;
    font-weight: 700;
    margin-top: 20px;
  }
  .confirm {
    padding: 8px 10px;
    border-radius: 5px;
    background-color: ${grayscale.gray};
    color: ${blue.bright};
    max-width: fit-content;
    margin: auto;
    margin-top: 25px;
    cursor: pointer;
    font-weight: ${fontWeight.bold};
    &:hover {
      background-color: ${grayscale.gray};
    }
  }
`;

import styled from "styled-components";
import { fontFamily } from "./Typography";
import { grayscale, blue } from "./Colors";

export const PseudoLink = styled.div`
  color: ${blue.bright};
  display: inline-block;
  text-decoration: underline;
  cursor: pointer;
`;

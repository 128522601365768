import React from "react";

// Dependencies
import moment from "moment";

//Components
import ClinicWelcomeNotification from "../Notifications/ClinicWelcomeNotification";
import ArchiveTutorial from "../Notifications/ArchiveTutorial";
import FollowupTutorial from "../Notifications/FollowupTutorial";
import Followup from "../Followup/Followup";
import Spinner from "../Utilities/Spinner";
import WaitingRoom from "./WaitingRoom";
import EncounterCard from "../Components/EncounterCard/EncounterCard"

// Styles
import {
  fontWeight,
  fontFamily,
  grayscale,
  blue,
  green,
  red,
} from "../Cytoplasm";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faTimes,
  faChevronRight,
  faPager,
} from "@fortawesome/pro-solid-svg-icons";

export default function ClinicEncounterStage(props) {
  const {
    loading,
    clinicWelcome,
    monthlyCyclesTutorial,
    setClinicWelcome,
    pendingEncounters,
    priorEncounters,
    archivedEncounters,
    getPriorEncounters,
    fetchArchivedEncounters,
    getPendingEncounters,
    archiveTutorial,
    setArchiveTutorial,
    statisticsTutorial,
    followupCycleEncounters,
    setMonthlyCyclesTutorial,
    setFollowupCycleEncounters,
    user,
    followupEncounters,
    setFollowupEncounters,
    followupTutorial,
    setFollowupTutorial,
  } = props;

  function renderEncounters() {
    if (loading) {
      return <Spinner />;
    }

    if (!clinicWelcome) {
      return (
        <ClinicWelcomeNotification
          user={user}
          dismissed={clinicWelcome}
          setClinicWelcome={setClinicWelcome}
        />
      );
    }

    if (!props.match.params.clinicLocation) {
      return <WaitingRoom {...props} />;
    } else if (props.match.params.clinicLocation == "archive") {
      if (!archivedEncounters) {
        fetchArchivedEncounters();
        return null;
      }
      if (!priorEncounters) {
        getPriorEncounters();
        return null;
      }

      if (archiveTutorial && archivedEncounters.length < 1) {
        return <p>You have no archived encounters</p>;
      }

      const completedArchivedEncounters = archivedEncounters.filter(
        (encounter) => {
          return priorEncounters.find((priorEncounter) => {
            return priorEncounter.hash_id == encounter.hash_id;
          });
        }
      );

      const archivedPriorEncounters = priorEncounters.filter((encounter) => {
        return archivedEncounters.find(
          (archivedEncounter) => encounter.hash_id == archivedEncounter.hash_id
        );
      });

      const pendingArchivedEncounters = archivedEncounters.filter(
        (encounter) => !completedArchivedEncounters.includes(encounter)
      );

      return (
        <>
          <ArchiveTutorial
            setArchiveTutorial={setArchiveTutorial}
            dismissed={archiveTutorial}
            user={user}
          />
          <div>
            {pendingArchivedEncounters.map((encounter) => {
              return (
                <EncounterContainer key={encounter.id}>

                  <EncounterCard encounter={encounter} {...props} />
                  <Followup
                    user={user}
                    hashId={encounter.hash_id}
                    setFollowupCycleEncounters={setFollowupCycleEncounters}
                    followupCycleEncounters={followupCycleEncounters}
                  />
                </EncounterContainer>
              );
            })}
          </div>
          <div>
            {archivedPriorEncounters.map((encounter) => {
              return <EncounterCard key={encounter.id} {...props} encounter={encounter} />
            })}
          </div>
        </>
      );
    } else if (props.match.params.clinicLocation == "history") {
      if (!priorEncounters) {
        getPriorEncounters();
        return null;
      }
      if (priorEncounters.length < 1) {
        return <p>You have no previous patient encounters</p>;
      }

      return priorEncounters.map((encounter) => {


        return (
          <EncounterContainer key={encounter.id}>
            <EncounterCard key={encounter.id} encounter={encounter} {...props} />
            <Followup
              user={user}
              hashId={encounter.hash_id}
              followupCycleEncounters={followupCycleEncounters}
              setFollowupCycleEncounters={setFollowupCycleEncounters}
            />
          </EncounterContainer>
        );
      });
    } else if (props.match.params.clinicLocation == "followups") {
      if (!followupCycleEncounters) {
        getPendingEncounters();
        return null;
      }
      return (
        <>
          {followupTutorial ? (
            <p>Manage encounter follow-up schedules here.</p>
          ) : (
              <FollowupTutorial
                setFollowupTutorial={setFollowupTutorial}
                dismissed={followupTutorial}
                user={user}
              />
            )
          }

          {followupCycleEncounters.map((encounter) => {
            return (
              <EncounterContainer key={encounter.hash_id}>
                <EncounterCard {...props} encounter={encounter} />

                <Followup
                  user={user}
                  hashId={encounter.hash_id}
                  followupCycleEncounters={followupCycleEncounters}
                  setFollowupCycleEncounters={setFollowupCycleEncounters}
                />
              </EncounterContainer>
            );
          })}
        </>
      );
    }
  }
  return <div>{renderEncounters()}</div>;
}

const EncounterContainer = styled.div`
  display: grid;
  grid-template-columns: auto min-content;
  grid-gap: 15px;
  align-items: center;
`;
const EncounterCardStyle = styled.div`
  cursor: pointer;
  padding: 20px;
  margin-bottom: 5px;
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr auto auto;
  grid-gap: 25px;
  align-items: center;
  .chevron {
    color: ${(props) =>
    props.encounterType == "prior encounter" ? `white` : `${blue.bright}`};
  }
  .date {
    font-size: 12px;
    font-weight: ${fontWeight.regular};
  }
  background: ${(props) =>
    props.encounterType == "prior encounter"
      ? `${grayscale.darkDark}`
      : `${grayscale.lightGray}`};
  color: ${(props) =>
    props.encounterType == "prior encounter" ? `white` : `black`};
`;

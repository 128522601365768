import React, { useState, useEffect } from "react";
import { url } from "../settings";

// Dependencies

//Components
import Header from "../Header/Header";
import Spinner from "../Utilities/Spinner";
import ClinicNav from "./ClinicNav";
import ClinicTabNav from "./ClinicTabNav";
import ClinicEncounterStage from "./ClinicEncounterStage";

// Styles
import styled from "styled-components";
import {
  fontWeight,
  fontFamily,
  grayscale,
  blue,
  red,
  green,
} from "../Cytoplasm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function ClinicHome(props) {
  const {
    user,
    pendingEncounters,
    priorEncounters,
    setStatistics,
    archivedEncounters,
    statistics,
    setPendingEncounters,
    setPriorEncounters,
    setArchivedEncounters,
    followupCycleEncounters,
    setFollowupCycleEncounters,
    followupEncounters,
    setFollowupEncounters,
  } = props;

  const [loading, setLoading] = useState(true);

  // NOTIFICATION STATES
  const [monthlyCyclesTutorial, setMonthlyCyclesTutorial] = useState(true);
  const [statisticsTutorial, setStatisticsTutorial] = useState(true);
  const [clinicWelcome, setClinicWelcome] = useState(true);
  const [archiveTutorial, setArchiveTutorial] = useState(true);
  const [followupTutorial, setFollowupTutorial] = useState(true);

  // PRIOR ENCOUNTERS
  function getPriorEncounters(prevPriorEncounters) {
    if (prevPriorEncounters) {
      // this situation only occurs when user has already fetched cases from backend
      setLoading(false);
      return pendingEncounters;
    } else {
      // if there are not currently loaded priorEncounters,
      // then fetch them from backend
      fetchPriorEncounters();
    }
  }
  function fetchPriorEncounters(callLocation) {
    // callLocation is only set withing Encounters.js
    return fetch(`${url}/rounds/fetch-prior-encounters/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setPriorEncounters(result);

        if (result.error) {
          console.log(result.error);
          throw result;
        }
        return result;
      })
      .then((response) => {
        setLoading(false);
      });
  }

  // PENDING ENCOUNTERS
  // START CLINIC
  function fetchPendingEncounters() {
    return fetch(`${url}/rounds/fetch-clinic/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setPendingEncounters(result.pending_encounters);

        setFollowupCycleEncounters(result.followup_cycle_encounters);
        setFollowupEncounters(result.followup_encounters);

        setStatistics({
          correct: result.clinicCorrect,
          incorrect: result.clinicIncorrect,
        });

        // NOTIFICATION
        // notifications return as true if the user has dimissed them
        setClinicWelcome(result.clinicWelcomeNotification);
        setMonthlyCyclesTutorial(result.monthlyCyclesTutorial);
        setArchiveTutorial(result.archiveTutorial);
        setStatisticsTutorial(result.statisticsTutorial);
        setFollowupTutorial(result.followupTutorial);

        if (result.error) {
          console.log(result.error);
          throw result;
        }
        return result;
      })
      .then((response) => {
        setLoading(false);
      });
  }
  function getPendingEncounters(prevPendingEncounters) {
    if (prevPendingEncounters) {
      if (prevPendingEncounters.length === pendingEncounters.length) {
        setLoading(false);
        return pendingEncounters;
      } else {
        fetchPendingEncounters();
      }
    } else {
      fetchPendingEncounters();
    }
  }

  // ARCHIVED ENCOUNTERS
  function fetchArchivedEncounters() {
    if (archivedEncounters) {
      setLoading(false);
      return archivedEncounters;
    } else {
      return fetch(`${url}/rounds/archive-encounter/`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          fetch: true,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          setArchivedEncounters(result.encounters);

          if (result.error) {
            console.log(result.error);
            throw result;
          }
          return result;
        })
        .then(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (props.match.params.clinicLocation == "history") {
      getPriorEncounters();
      getPendingEncounters();
    } else if (props.match.params.clinicLocation == "archive") {
      fetchArchivedEncounters();
      getPendingEncounters();
    } else {
      getPendingEncounters();
    }
  }, []);

  useEffect(() => {
    if (props.match.params.clinicLocation === undefined && pendingEncounters) {
      setLoading(false);
    }
  }, [pendingEncounters]);

  useEffect(() => {
    if (props.match.params.clinicLocation == "history" && priorEncounters) {
      setLoading(false);
    }
  }, [priorEncounters]);

  const [clinicLocation, setClinicLocation] = useState();
  const [clinicHeading, setClinicHeading] = useState();
  useEffect(() => {
    if (!props.match.params.clinicLocation) {
      setClinicHeading("Waiting Room");
    } else if (props.match.params.clinicLocation == "history") {
      setClinicHeading("History");
    } else if (props.match.params.clinicLocation == "archive") {
      setClinicHeading("Archive");
    } else if (props.match.params.clinicLocation == "followups") {
      setClinicHeading("Follow-Ups");
    }
  }, [props.match.params.clinicLocation]);

  function changeClinicView(params) {
    setLoading(true);
    window.scrollTo(0, 0);
    props.history.push(params);

    if (params == "/history") {
      getPriorEncounters(priorEncounters);
    } else if (params == "/") {
      getPendingEncounters(pendingEncounters);
    } else if (params == "/archive") {
      fetchArchivedEncounters();
    } else if (params == "/followups") {
      getPendingEncounters(pendingEncounters);
    }
  }

  return (
    <>
      <Header user={user} {...props} />

      <AppContainer>
        <ClinicContainer>
          <ClinicNav
            location={props.match.params.clinicLocation}
            changeClinicView={changeClinicView}
          />
          <ClinicTabNav
            location={props.match.params.clinicLocation}
            changeClinicView={changeClinicView}
          />

          <div>
            <h1 style={{ display: "inline-block" }}>{clinicHeading}</h1>
            <StatisticsContainer>
              {statistics && (
                <>
                  <span>Correct: {statistics.correct}%</span>
                  <span
                    style={{
                      marginLeft: "15px",
                      color: `${red.bright}`,
                      fontStyle: "italic",
                    }}>
                    Incorrect: {statistics.incorrect}%
                  </span>
                </>
              )}
            </StatisticsContainer>

            <ClinicEncounterStage
              pendingEncounters={pendingEncounters}
              getPendingEncounters={getPendingEncounters}
              getPriorEncounters={getPriorEncounters}
              priorEncounters={priorEncounters}
              archivedEncounters={archivedEncounters}
              fetchArchivedEncounters={fetchArchivedEncounters}
              loading={loading}
              clinicWelcome={clinicWelcome}
              monthlyCyclesTutorial={monthlyCyclesTutorial}
              setMonthlyCyclesTutorial={setMonthlyCyclesTutorial}
              setClinicWelcome={setClinicWelcome}
              statisticsTutorial={statisticsTutorial}
              archiveTutorial={archiveTutorial}
              setArchiveTutorial={setArchiveTutorial}
              followupCycleEncounters={followupCycleEncounters}
              setFollowupCycleEncounters={setFollowupCycleEncounters}
              followupEncounters={followupEncounters}
              setFollowupEncounters={setFollowupEncounters}
              followupTutorial={followupTutorial}
              setFollowupTutorial={setFollowupTutorial}
              {...props}
            />
          </div>
        </ClinicContainer>
      </AppContainer>
    </>
  );
}

const AppContainer = styled.div`
  margin: auto;
  padding: 15px;
  max-width: 1100px;
  margin-bottom: 100px;
  font-family: ${fontFamily.sansSerif};
  margin-top: 75px;
  @media (max-width: 768px) {
    margin-top: 50px;
    h1 {
      margin-bottom: 0;
    }
  }
  @media (max-width: 425px) {
    margin-top: 20px;
  }
`;
const ClinicContainer = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 5fr;
  grid-gap: 50px;
  h1 {
    margin-top: 0;
  }
  @media (max-width: 425px) {
    grid-template-columns: 1fr;
  }
`;

const StatisticsContainer = styled.div`
  margin-left: 15px;
  font-style: italic;
  color: ${green.dark};
  display: inline-block;
  margin-bottom: 25px;
  @media (max-width: 768px) {
    display: block;
    margin-left: 0;
  }
`;

import React, { useState, useEffect } from "react";

// Dependencies

//Components

// Styles
import styled from "styled-components";
import {
  fontWeight,
  fontFamily,
  grayscale,
  blue,
  green,
  red,
} from "../Cytoplasm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClinicMedical,
  faHistory,
  faSignOut,
  faArchive,
  faRepeat,
} from "@fortawesome/pro-regular-svg-icons";

export default function ClinicTabNav(props) {
  const { location, changeClinicView } = props;
  return (
    <NavFixedContainer>
      <NavContainer>
        <NavBox
          location={location == null ? "highlight" : null}
          onClick={() => changeClinicView("/")}
        >
          <FontAwesomeIcon icon={faClinicMedical} />
          <div className="nav-text">Waiting Room</div>
        </NavBox>
        <NavBox
          location={location == "history" ? "highlight" : null}
          onClick={() => changeClinicView("/history")}
        >
          <FontAwesomeIcon icon={faHistory} />
          <div className="nav-text">History</div>
        </NavBox>
        <NavBox
          location={location == "followups" ? "highlight" : null}
          onClick={() => changeClinicView("/followups")}
        >
          <FontAwesomeIcon icon={faRepeat} />
          <div className="nav-text">
            <div>Followups</div> 
            {/* <div className="new">NEW</div> */}
          </div>
        </NavBox>
        <NavBox
          location={location == "archive" ? "highlight" : null}
          onClick={() => changeClinicView("/archive")}
        >
          <FontAwesomeIcon icon={faArchive} />
          <div className="nav-text">
            <div>Archive</div> 
            {/* <div className="new">NEW</div> */}
          </div>
        </NavBox>
        <NavBox onClick={() => (window.location = "http://medzcool.com")}>
          <FontAwesomeIcon icon={faSignOut} />
          <div className="nav-text">Exit</div>
        </NavBox>
      </NavContainer>
    </NavFixedContainer>
  );
}
const NavFixedContainer = styled.div`
  display: none;
  position: fixed;
  z-index:99;
  bottom: 0;
  left: 0;
  background-color: ${grayscale.lightGray};
  border-top: 1px solid ${grayscale.gray};
  width: 100%;
  padding-bottom: 30px;
  @media (max-width: 425px) {
    display: block;
  }
`;
const NavContainer = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 15px;
  justify-items: center;
`;
const NavBox = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
  border-radius: 4px;
  text-align: center;
  .nav-text {
    margin-top: 5px;
    font-size: 12px;
  }
  .new {
    color: orange;
    font-size: 10px;
    font-weight: ${fontWeight.heavy};
  }

  color: ${(props) => props.location == "highlight" && `${blue.bright}`};
`;

import React, { useState, useEffect } from "react";

// Dependencies

//Components

// Styles
import styled from "styled-components";
import {
  fontFamily,
  grayscale,
  blue,
  green,
  red,
  fontWeight,
} from "../Cytoplasm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClinicMedical,
  faHistory,
  faArchive,
  faRepeat,
} from "@fortawesome/pro-regular-svg-icons";

export default function ClinicNav(props) {
  const { location, changeClinicView } = props;
  return (
    <NavDesktopContainer>
      <NavDesktop
        location={location == null ? "highlight" : null}
        onClick={() => changeClinicView("/")}
      >
        <FontAwesomeIcon icon={faClinicMedical} />
        <span>Waiting Room</span>
      </NavDesktop>

      <NavDesktop
        location={location == "history" ? "highlight" : null}
        onClick={() => changeClinicView("/history")}
      >
        <FontAwesomeIcon icon={faHistory} />
        <span>History</span>
      </NavDesktop>

      <NavDesktop
        location={location == "followups" ? "highlight" : null}
        onClick={() => changeClinicView("/followups")}
      >
        <FontAwesomeIcon icon={faRepeat} />
        <span>Follow-Ups</span>
        {/* <span className="new">NEW</span> */}
      </NavDesktop>

      <NavDesktop
        location={location == "archive" ? "highlight" : null}
        onClick={() => changeClinicView("/archive")}
      >
        <FontAwesomeIcon icon={faArchive} />
        <span>Archive</span>
      </NavDesktop>
    </NavDesktopContainer>
  );
}
const NavDesktopContainer = styled.div`
  margin-top: 15px;
  @media (max-width: 425px) {
    display: none;
  }
`;
const NavDesktop = styled.div`
  cursor: pointer;
  margin-bottom: 5px;
  padding: 8px 15px;
  border-radius: 4px;
  span {
    margin-left: 15px;
  }
  .new {
    color: orange;
    font-size: 12px;
    font-weight: ${fontWeight.bold};
  }
  background-color: ${(props) =>
    props.location == "highlight" && `${grayscale.lightGray}`};
`;

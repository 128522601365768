import React, { Fragment, lazy, Suspense, useEffect, useState } from "react";
import { url, homeUrl } from "./settings";
import logo from "./logo.svg";
import "./App.css";

// Dependencies

// Components
import { mixpanelToken } from "./settings";

// Components
import mixpanel from "mixpanel-browser";
import ClinicHome from "./Clinic/ClinicHome";
import CaseDetails from "./CaseDetails/CaseDetails";
import Spinner from "./Utilities/Spinner";
import ServerError from "./Utilities/ServerError";
import { ErrorMessage } from "./Utilities/ErrorMessage";

// Dependencies
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// Apollo, GraphQL
import { Query } from "@apollo/client/react/components";
import { gql, useQuery } from "@apollo/client";

// Lazy Loaded Components

const GET_USER = gql`
  query GET_USER {
    user {
      id
      isSuperuser
      username
      email
      profile {
        id
        subscribedRounds {
          id
          collectionTitle
          description
          checkoutThumbnail
          collectionUrl
        }
        latestInvoiceId
        subscriptionStatus
        paymentIntentStatus
        stripeCustomerId
        subscriptionId
        priceId
        productId
        stripeCustomerPaymentMethodId
        stripeExpMonth
        stripeExpYear
        stripeLast4
        stripeCardBrand
        custom
      }
    }
  }
`;

mixpanel.init(mixpanelToken);

export default function App(props) {
  const { client } = props;
  const { loading, error, data } = useQuery(GET_USER, {
    onCompleted: (data) => {
      console.log(data.user);
      mixpanel.identify(data.user.id);
    },
  });

  // CLINIC START
  const [pendingEncounters, setPendingEncounters] = useState();
  const [statistics, setStatistics] = useState();
  const [followupCycleEncounters, setFollowupCycleEncounters] = useState();
  const [followupEncounters, setFollowupEncounters] = useState();
  function fetchPendingEncounters() {
    return fetch(`${url}/rounds/fetch-clinic/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setPendingEncounters(result.pending_encounters);

        setFollowupCycleEncounters(result.followup_cycle_encounters);
        setFollowupEncounters(result.followup_encounters);

        setStatistics({
          correct: result.clinicCorrect,
          incorrect: result.clinicIncorrect,
        });

        if (result.error) {
          console.log(result.error);
          throw result;
        }
        return result;
      });
  }
  function getPendingEncounters(prevPendingEncounters) {
    if (!prevPendingEncounters) {
      // console.log("fetching pending")
      fetchPendingEncounters();
    }
  }

  // PRIOR ENCOUNTERS
  const [priorEncounters, setPriorEncounters] = useState();
  function getPriorEncounters(prevPriorEncounters) {
    if (!prevPriorEncounters) {
      // console.log("fetching prior")
      fetchPriorEncounters();
    }
  }
  function fetchPriorEncounters(callLocation) {
    return fetch(`${url}/rounds/fetch-prior-encounters/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setPriorEncounters(result);

        if (result.error) {
          console.log(result.error);
          throw result;
        }
        return result;
      });
  }

  // ARCHIVED ENCOUNTERS
  const [archivedEncounters, setArchivedEncounters] = useState();
  function fetchArchivedEncounters() {
    if (archivedEncounters) {
      return archivedEncounters;
    } else {
      return fetch(`${url}/rounds/archive-encounter/`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          fetch: true,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          setArchivedEncounters(result.encounters);

          if (result.error) {
            console.log(result.error);
            throw result;
          }
          return result;
        });
    }
  }

  if (loading) return <Spinner />;
  if (error) return <ServerError />;
  const user = data.user;

  if (!user || user.profile.subscriptionStatus != "active") {
    window.location = "https://medzcool.com/clinic";
    return null;
  }

  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route
            exact
            path="/:clinicLocation?"
            render={(props) => (
              <ClinicHome
                {...props}
                client={client}
                user={user}
                setPriorEncounters={setPriorEncounters}
                setPendingEncounters={setPendingEncounters}
                pendingEncounters={pendingEncounters}
                priorEncounters={priorEncounters}
                setStatistics={setStatistics}
                archivedEncounters={archivedEncounters}
                setArchivedEncounters={setArchivedEncounters}
                loading={loading}
                statistics={statistics}
                followupCycleEncounters={followupCycleEncounters}
                setFollowupCycleEncounters={setFollowupCycleEncounters}
                followupEncounters={followupEncounters}
                setFollowupEncounters={setFollowupEncounters}
              />
            )}
          />
          <Route
            exact
            path="/encounter/:caseId"
            render={(props) => (
              <CaseDetails
                {...props}
                user={user}
                fromClinic
                mixpanel={mixpanel}
                getPriorEncounters={getPriorEncounters}
                priorEncounters={priorEncounters}
                fetchArchivedEncounters={fetchArchivedEncounters}
                setPendingEncounters={setPendingEncounters}
                setPriorEncounters={setPriorEncounters}
                setArchivedEncounters={setArchivedEncounters}
                archivedEncounters={archivedEncounters}
                getPendingEncounters={getPendingEncounters}
                followupEncounters={followupEncounters}
                setFollowupEncounters={setFollowupEncounters}
                followupCycleEncounters={followupCycleEncounters}
                setFollowupCycleEncounters={setFollowupCycleEncounters}
              />
            )}
          />
        </Switch>
      </Suspense>
    </Router>
  );
}

import styled from "styled-components";

export const BaseContainer = styled.div`
  max-width: 1300px;
  margin: auto;
  margin-bottom: 100px;
  padding-top: 50px;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 768px) {
  }
`;

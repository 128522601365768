import React, { useState, useEffect } from "react";
import { url } from "../settings";

// Stylesheet
import styled from "styled-components";
import { grayscale, blue } from "../Cytoplasm";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolderPlus,
  faArchive as faArchiveSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { faArchive as faArchiveLight } from "@fortawesome/pro-light-svg-icons";

export default function Archive(props) {
  const { user, caseId, archivedEncounters, setArchivedEncounters } = props;

  const [archived, setArchived] = useState(false);

  useEffect(() => {
    if (archivedEncounters) {
      const isArchived = archivedEncounters.find((encounter) => {
        return encounter.hash_id == caseId;
      });
      if (isArchived) {
        setArchived(true);
      }
    }
  }, [archivedEncounters]);


  function handleClick() {
    setArchived(!archived);
    fetch(`${url}/rounds/archive-encounter/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
        hashId: props.caseId,
        fetch: false,
      }),
    }).then((response) => {
      return response.json();
    }).then((result) => {
      setArchivedEncounters(result.encounters)
      return;
    });
  }

  return (
    <Container onClick={handleClick} archived={archived}>
      <span className="icon-container">
        <FontAwesomeIcon
          icon={archived ? faArchiveSolid : faArchiveLight}
          size={"lg"}
        />
      </span>
      {archived ? "Archived" : "Save to Archive"}
    </Container>
  );
}

const Container = styled.div`
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  .icon-container {
    margin-right: 8px;
  }
  color: ${(props) =>
    props.archived ? `${blue.bright}` : `${grayscale.lightDark}`};
`;

export const themeColors = {
  black: "#000000",
  dark: "#1D1D1D",
  gray: "#898989",
  lightGray: "#E1E1E1"
};

export const blue = {
  bright: "#0071e3",
  neutral: "#3b5998"
};

export const grayscale = {
  darkDark: "#333333",
  lightDark: "#999999",
  gray: "#D8D8D8",
  lightGray: "#F2F2F2",
  offWhite: "#FAFAFA"
};

export const red = {
  dark: "#D23D56",
  bright: "#F0445D",
  pink: "#fd2c9b"
};

export const green = {
  dark: "#008C82",
  bright: "#5ED1A5"
};

export const yellow = {
  dark: "#E67E22",
  light: "#ffc820"
};

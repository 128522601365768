import styled from "styled-components";

export const fontFamily = {
  sansSerif: "futura-pt, sans-serif",
};

export const fontWeight = {
    light: 300,
    regular: 400,
    heavy: 500,
    bold: 700
}

import React, { useState, useEffect, Fragment, Suspense, lazy } from "react";
import { url } from "../settings";

// Dependencies
import axios from "axios";

// Components
import CaseStem from "./CaseStem";
import CaseQuestionAnswer from "./CaseQuestionAnswer";
import Spinner from "../Utilities/Spinner";
import CaseDetailsHeader from "../Header/CaseDetailsHeader";
import LiveEncounter from "@bit/medzcool.cytoplasm.live-encounter";
import { BaseType } from "@bit/medzcool.cytoplasm.typography";

// Styles
import styled from "styled-components";
import {
  purple,
  green,
  red,
  grayscale,
  fontFamily,
  LoadingContainer,
} from "../Utilities";

// Apollo, GraphQL
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_ENCOUNTER } from "../DataStructures/Queries";
import { ANSWER_CLINIC_ENCOUNTER } from "../DataStructures/Mutations";

const CaseChoices = lazy(() => import("./CaseChoices"));
const CaseExplanation = lazy(() => import("./CaseExplanation"));

export default function CaseDetails(props) {
  const {
    fromClinic,
    user,
    mixpanel,
    fetchArchivedEncounters,
    setPendingEncounters,
    priorEncounters,
    setPriorEncounters,
    setArchivedEncounters,
    archivedEncounters,
    followupEncounters,
    setFollowupEncounters,
    getPriorEncounters,
    getPendingEncounters,
    setFollowupCycleEncounters,
    followupCycleEncounters,
  } = props;

  const caseId = props.match.params.caseId;

  // 1. Check to see if encounter hashId exists in medzcooldb, if not, then check codehealth

  const [answerClinicEncounter] = useMutation(ANSWER_CLINIC_ENCOUNTER, {
    onCompleted(data) {
      const encounterData = data.answerClinicEncounter;

      mixpanel.track("Clinic", {
        Action: "Answered Encounter",
        encounterHash: encounterData.encounter.hashId,
      });

      fetch(`${url}/rounds/update-clinic-new/`, {
        method: "post",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => {
          if (result.error) {
            console.log(result.error);
            throw result;
          }
          return result;
        })
        .then((result) => {
          setPendingEncounters(result.pendingEncounters);
          setPriorEncounters(result.priorEncounters);
          setFollowupEncounters(result.followupEncounters);
          return;
        })
        .then(() => {
          setAnswered(true);
        });
    },
  });

  const [getEncounter, { loading, error, data }] = useLazyQuery(GET_ENCOUNTER, {
    onCompleted(data) {
      setLoadingCase(false);
    },
  });

  // INITIALIZE ENCOUNTER
  // GET ARCHIVED ENCOUNTERS
  const [caseObject, setCaseObject] = useState();
  const [loadingCase, setLoadingCase] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchArchivedEncounters();

    if (!followupEncounters || !priorEncounters) {
      getPendingEncounters(followupEncounters);
      getPriorEncounters(priorEncounters);
    }

    getEncounter({ variables: { hashId: caseId } });
  }, []);

  useEffect(() => {
    if (error) {
      axios({
        method: "post",
        url: "https://codehealth.io/api/v0.1/fetch-case/",
        data: {
          caseId: caseId,
        },
      }).then(function (response) {
        setCaseObject(response.data.data);
        setLoadingCase(false);
      });
    }
  }, [error]);

  // THIS IS USED WHEN USER NAVIGATES BETWEEN ENCOUNTERS
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoadingCase(true);
    setCaseObject();
    setSelectedChoice();
    setAnswered(false);
    setCorrect();
    if (error) {
      axios({
        method: "post",
        url: "https://codehealth.io/api/v0.1/fetch-case/",
        data: {
          caseId: caseId,
        },
      }).then(function (response) {
        setCaseObject(response.data.data);
        setLoadingCase(false);
      });
    }
  }, [caseId]);

  useEffect(() => {
    if (priorEncounters && followupEncounters) {
      const isPriorEncounter = priorEncounters.find(
        (encounter) => encounter.hash_id == caseId
      );
      const isFollowupEncounter = followupEncounters.find(
        (encounter) => encounter.hash_id == caseId
      );

      if (isFollowupEncounter) {
        setAnswered(false);
        return;
      }

      if (isPriorEncounter) {
        if (isPriorEncounter.encounterAnswer == "correct") {
          setCorrect(true);
        }
        setAnswered(true);
      }
    }
  }, [followupEncounters, priorEncounters]);

  const [selectedChoice, setSelectedChoice] = useState();
  function handleChoiceChange(event) {
    if (!answered) {
      setSelectedChoice(event.target.value);
    }
  }

  const [answered, setAnswered] = useState(false);
  const [correct, setCorrect] = useState();
  function handleAnswer(choices) {
    window.scrollTo(0, 0);

    // 1. Get the correct answer choice
    const correctChoice = choices.find((choice) => {
      return choice.correct_answer === true;
    });

    // 2. Compare user's selected choice with the correct answer choice
    // 3. Set case state to 'answered'
    const answerId = selectedChoice;
    if (selectedChoice == correctChoice.id) {
      setCorrect(true);
      handleCaseAnswerState("correct", caseId, answerId);
    } else {
      handleCaseAnswerState("incorrect", caseId, answerId);
    }
  }

  function handleCaseAnswerState(answer, caseId, answerId = null) {
    fetch(`${url}/rounds/update-clinic/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
        hashId: caseId,
        answer: answer,
        answerId: answerId,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.error) {
          console.log(result.error);
          throw result;
        }
        return result;
      })
      .then((result) => {
        setPendingEncounters(result.pendingEncounters);
        setPriorEncounters(result.priorEncounters);
        setFollowupEncounters(result.followupEncounters);
        return;
      })
      .then(() => {
        setAnswered(true);
      });
  }

  function createMarkup(html) {
    return { __html: html };
  }

  function createDraftText(caseObject) {
    if (caseObject.caseType == "clinical scenario") {
      return caseObject.stems[0].stem;
    } else if (caseObject.caseType == "question") {
      return caseObject.questions[0].question;
    } else {
      return null;
    }
  }

  if (loadingCase || !priorEncounters || !followupEncounters)
    return (
      <LoadingContainer>
        <Spinner />
      </LoadingContainer>
    );

  if (loading) return null;
  if (data && data.encounter) {
    return (
      <BaseType>
        <CaseDetailsHeader
          user={props.user}
          {...props}
          correct={correct}
          answered={answered}
          fromClinic={fromClinic}
          archivedEncounters={archivedEncounters}
          setArchivedEncounters={setArchivedEncounters}
          setFollowupCycleEncounters={setFollowupCycleEncounters}
          followupCycleEncounters={followupCycleEncounters}
          style={{ height: 60 }}
        />
        <LiveEncounter
          encounter={data.encounter}
          answerEncounter={answerClinicEncounter}
          user={props.user}
          exitLocation={() => props.history.goBack()}
          eventLocation="clinic"
          clinicEncounterAnswered={answered}
        />
      </BaseType>
    );
  }

  return (
    <Fragment>
      <CaseDetailsHeader
        user={props.user}
        {...props}
        correct={correct}
        answered={answered}
        fromClinic={fromClinic}
        archivedEncounters={archivedEncounters}
        setArchivedEncounters={setArchivedEncounters}
        setFollowupCycleEncounters={setFollowupCycleEncounters}
        followupCycleEncounters={followupCycleEncounters}
      />
      <CaseMainContainer answered={answered} correct={correct}>
        <CaseSubContainer answered={answered}>
          <MainCaseBlock>
            <CaseStem
              caseType={caseObject.caseType}
              answered={answered}
              caseLayout={caseObject.layout}
              oldStem={createMarkup(caseObject.clinicalScenario)}
              stem={createDraftText(caseObject)}
              oldQuestion={createMarkup(caseObject.clinicalScenario)}
              question={createDraftText(caseObject)}
            />

            <ChoicesBlock answered={answered} mobile={1} className="mobile">
              <CaseBlock>
                <CaseQuestionAnswer
                  caseObject={caseObject}
                  createMarkup={createMarkup}
                />
                <Suspense fallback={<Loading>Loading....</Loading>}>
                  <CaseChoices
                    caseObject={caseObject}
                    handleChoiceChange={handleChoiceChange}
                    caseHash={props.match.params.caseHash}
                    handleAnswer={handleAnswer}
                    selectedChoice={selectedChoice}
                    answered={answered}
                    correct={correct}
                    user={props.user}
                    fromClinic={fromClinic}
                    {...props}
                  />
                </Suspense>
              </CaseBlock>
            </ChoicesBlock>
            <Suspense fallback={<Loading>Loading....</Loading>}>
              <CaseExplanation
                answered={answered}
                caseHash={props.match.params.caseHash}
                caseObject={caseObject}
              />
            </Suspense>
          </MainCaseBlock>

          <ChoicesBlock answered={answered}>
            <CaseBlock>
              <CaseQuestionAnswer
                caseObject={caseObject}
                createMarkup={createMarkup}
              />
              <Suspense fallback={<Loading>Loading....</Loading>}>
                <CaseChoices
                  caseObject={caseObject}
                  handleChoiceChange={handleChoiceChange}
                  caseHash={props.match.params.caseHash}
                  handleAnswer={handleAnswer}
                  selectedChoice={selectedChoice}
                  answered={answered}
                  correct={correct}
                  user={props.user}
                  fromClinic={fromClinic}
                  {...props}
                />
              </Suspense>
            </CaseBlock>
          </ChoicesBlock>
        </CaseSubContainer>
      </CaseMainContainer>
    </Fragment>
  );
}

const CaseBlock = styled.div`
  margin: 15px 0;
  background: white;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 25px;
  position: relative;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;
const StyledLabel = styled.div`
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: gray;
  align: left;
  margin-bottom: 15px;
`;

const CaseMainContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  padding: 0px;
  margin: auto;
  background: ${grayscale.lightGray};

  a {
    color: ${purple.darkLight};
    ${(props) => props.correct && props.answered && `color: ${green.bright};`}
    ${(props) => !props.correct && props.answered && `color: ${red.bright};`}
  }
  .old-case-text {
    line-height: 30px;
    font-family: adobe-text-pro, serif;
    font-size: 18px;
  }
  .question {
    margin-bottom: 10px;
  }
  .public-DraftStyleDefault-block {
    margin-bottom: 10px;
  }

  @media (max-width: 576px) {
    .old-case-text {
      font-size: 16px;
      line-height: 25px;
    }
  }
`;
const CaseSubContainer = styled.div`
  padding: 0px;
  max-width: 1200px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 150px;
  ${(props) => !props.answered && `margin-bottom: 100px;`}
`;
const MainCaseBlock = styled.div`
  margin: 0 15px 0 11px;
  display: inline-block;
  width: 60%;
  vertical-align: top;
  @media (max-width: 992px) {
    width: 58.4%;
    margin: 0 10px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;
const ChoicesBlock = styled.div`
  display: inline-block;
  width: 36.5%;
  vertical-align: top;
  ${(props) => props.mobile && `display: none`};

  @media (max-width: 992px) {
    width: 38.4%;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    ${(props) => props.mobile && `display: block`};
    ${(props) => !props.mobile && `display: none`};
  }
`;

const ExplanationCaseBlock = styled(CaseBlock)`
  display: none;
  ${(props) => props.answered && `display: block;`}
  img {
    width: 100%;
  }
`;
const TitleCaseBlock = styled(CaseBlock)`
  border-radius: 0;
  margin: 15px 0;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 10px;
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 0;
  z-index: 99;
  width: 100%;
  background: white;
  min-height: 30px;
  h2 {
    font-family: futura-pt, sans-serif;
    margin: 5px;
    max-width: 1100px;
    margin: auto;
    font-size: 20px;
    @media (max-width: 1200px) {
      display: inline-block;
      position: relative;
      vertical-align: middle;
      max-width: 76%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  ${(props) =>
    props.correct &&
    props.answered &&
    `background: ${green.bright}; color:white;`}
  ${(props) =>
    !props.correct &&
    props.answered &&
    `background: ${red.bright};color:white;`}
`;
const BackButton = styled.button`
  border: 0;
  color: black;
  padding: 0;
  outline: none;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 25px;
  transform: translateY(-50%);
  cursor: pointer;
  ${(props) => props.answered && `color: white;`}
  @media (max-width: 1200px) {
    display: inline-block;
    position: relative;
    margin: 0;
    transform: none;
    vertical-align: middle;
    left: 0;
    margin: 0 25px 0 15px;
  }
`;
const Loading = styled.div`
  font-family: adobe-text-pro, serif;
  font-size: 16px;
  text-align: center;
  color: gray;
`;
const DoesNotExist = styled.div`
  max-width: 900px;
  margin: auto;
  padding: 50px 15px 15px 15px;
  text-align: center;
  font-family: ${fontFamily.sansSerif};
  h2 {
    margin-bottom: 0;
  }
  p {
    margin: 0;
  }
`;

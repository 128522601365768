import React, { useState, useEffect } from "react";
// DEPENDENCIES
// COMPONENTS
import { SolidButton } from "@bit/medzcool.cytoplasm.buttons";
// STYLES
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { grayscale } from "@bit/medzcool.cytoplasm.colors";

export default function Modal(props) {
  const { openState, setOpenState, title } = props;

  const [modalIsOpen, setModalIsOpen] = useState(false);
  useEffect(() => {
    if (openState) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  }, [openState]);

  function closeModal() {
    setOpenState(false);
  }

  if (!modalIsOpen) return null;

  return (
    <ModalContainer>
      <ModalBox>
        <div className="modal-header-container">
          <div className="modal-header-grid">
            <h4 className="modal-title">{title}</h4>
            <SolidButton onClick={closeModal} className="exit-modal">
              <FontAwesomeIcon icon={faTimes} size="lg" />
            </SolidButton>
          </div>
        </div>

        <div className="modal-body">{props.children}</div>
      </ModalBox>
      <ModalBackground onClick={closeModal} />
    </ModalContainer>
  );
}

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
`;
const ModalBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 25px;
  z-index: 100;
  position: relative;
  top: 50%;
  left: 50%;
  max-width: 500px;
  transform: translate(-50%, -50%);
  max-height: 80vh;

  .modal-header-container {
    position: fixed;
    width: 100%;
    left: 0;
    top: 10px;
    padding: 0px 15px 10px 25px;
    background: white;
    border-bottom: ${grayscale.gray} 1px solid;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
    .modal-header-grid {
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: 10px;
      align-items: center;
    }
    .modal-title {
      margin-top: 0;
      margin-bottom: 0;
    }
    ${SolidButton} {
      background-color: transparent;
      color: black;
      width: auto;
    }
  }
  .modal-body {
    padding-top: 50px;
    overflow-y: scroll;
    max-height: 70vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .exit-modal {
    cursor: pointer;
  }
`;
const ModalBackground = styled.div`
  background: black;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 35%;
  z-index: 99;
`;

/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ENCOUNTER = gql`
  query getEncounter($hashId: String!) {
    encounter(hashId: $hashId) {
      id
      hashId
      encounterType
      title
      description
      thumbnail
      dateCreated
      attention
      isPublic
      author {
        id
        username
      }
      noteSet {
        id
        date
        text
        user {
          id
        }
      }
      tags {
        id
        name
      }
      documentSet {
        id
        title
        body
        metadata
      }
      questionSet {
        id
      }
      summary {
        id
        title
        body
        metadata
      }
      encounterlogicSet {
        id
        order
        document {
          id
          title
          body
          metadata
        }
        question {
          id
          text
          votes
          choiceSet {
            id
            text
            correctAnswer
            votes
          }
        }
        explanation {
          id
          title
          body
          metadata
        }
      }
    }
  }
`;

import React, { useState, Fragment } from "react";
import { useSpring, animated } from "react-spring";
import { url } from "../settings";

// Components
import Menu from "./Menu";
import Archive from "../CaseDetails/Archive";
import Followup from "../Followup/Followup";

// Dependencies
import { Link } from "react-router-dom";

// Utilities
import { StyledButton, green, red } from "../Utilities";

// Styles
import styled from "styled-components";
import { themeColors } from "../Utilities";
import { grayscale, fontFamily, fontWeight } from "../Cytoplasm";
import { Logo } from "../Typography";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faBars,
  faSignOut,
  faArchive,
} from "@fortawesome/pro-regular-svg-icons";
import { faBackward } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";

import { Mutation, Query } from "react-apollo";
import gql from "graphql-tag";

const LOGOUT = gql`
  mutation logout {
    logout {
      user {
        id
      }
    }
  }
`;

export default function CaseDetailsHeader(props) {
  const user = props.user;
  const {
    answered,
    correct,
    collection,
    fromClinic,
    archivedEncounters,
    setArchivedEncounters,
    followupCycleEncounters,
    setFollowupCycleEncounters,
  } = props;

  const caseId = props.match.params.caseId;

  function homeBack() {
    props.history.goBack();
  }

  return (
    <HeaderContainer correct={correct} answered={answered}>
      <HeaderInnerContainer>
        <HeaderLeft>
          <div className="menu-item">
            <div
              onClick={homeBack}
              fontSize={"14px;"}
              padding={"15px;"}
              background={"none;"}
              width={"fit-content;"}>
              <FontAwesomeIcon icon={answered ? faTimes : faBackward} />
            </div>
          </div>
        </HeaderLeft>
        <HeaderRight>
          <div className="followup-container">
            <Followup
              user={user}
              hashId={caseId}
              encounterHeader
              setFollowupCycleEncounters={setFollowupCycleEncounters}
              followupCycleEncounters={followupCycleEncounters}
            />
          </div>
          <Archive
            caseId={caseId}
            user={user}
            archivedEncounters={archivedEncounters}
            setArchivedEncounters={setArchivedEncounters}
          />
        </HeaderRight>
      </HeaderInnerContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 50px;
  background-color: white;
  border-bottom: 1px solid ${grayscale.gray};
  ${(props) =>
    props.correct &&
    props.answered &&
    `border-bottom: 4px solid ${green.bright}`}
  ${(props) =>
    !props.correct &&
    props.answered &&
    `border-bottom: 4px solid ${red.bright}`}
`;
const HeaderInnerContainer = styled.div`
  display: grid;
  height: 100%;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  margin: auto;
  padding: 0 15px;
  @media (max-width: 768px) {
    padding: 0 10px;
    grid-template-columns: auto auto;
  }
`;
const HeaderRight = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-self: end;
  align-items: center;
  font-family: ${fontFamily.sansSerif};
  .menu-item {
    cursor: pointer;
    a {
      text-decoration: none;
    }
    &.sign-up {
      margin-right: 25px;
    }
  }
  .archive-btn {
    .archive-icon {
      display: inline-block;
    }
    .archive-text {
      margin-left: 5px;
      display: inline-block;
    }
  }
  .followup-container {
    margin-right: 15px;
    font-size: 16px;
  }
  @media (max-width: 425px) {
    font-size: 12px;
  }
`;
const HeaderLeft = styled.div`
  width: 300px;
  .menu-item {
    font-family: ${fontFamily.sansSerif};
    cursor: pointer;
    a {
      text-decoration: none;
    }
  }
  .mobile {
    display: none;
  }
  @media (max-width: 768px) {
    width: 100%;
    .desktop {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;

import React, { useState } from "react";
import { url } from "../settings";

// Dependencies

//Components

// Styles
import styled from "styled-components";
import { fontFamily } from "../Typography";
import { grayscale, blue } from "../Utilities";
import { fontWeight } from "../Cytoplasm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat as faRepeatDuo } from "@fortawesome/pro-duotone-svg-icons";
import { faRepeat as faRepeatReg } from "@fortawesome/pro-light-svg-icons";

export default function FollowupTutorial(props) {
  const { user, dismissed, setFollowupTutorial } = props;

  function dismissNotification() {
    setFollowupTutorial(true);
    setVisible(false);
    return fetch(`${url}/rounds/dismiss-notification/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
        notification: "followup_tutorial",
      }),
    }).then((response) => {
      return response;
    });
  }

  const [visible, setVisible] = useState(true);
  if (!visible) return null;
  if (dismissed) return null;

  return (
    <TutorialContainer>
      <FontAwesomeIcon icon={faRepeatDuo} size="6x" />
      <div className="text">FOLLOW-UPS</div>
      <div className="big-text">Learn by repetition</div>
      <div className="text">
        Add encounters to a follow-up schedule. This allows you to be re-exposed
        to a disease or encounter to facilitate learning by{" "}
        <i>spaced-repetition</i>. There are 4 follow-up cycles:
      </div>
      <div className="tutorial-block">
        <div className="icon-block">
          <div className="icon">
            <FontAwesomeIcon icon={faRepeatReg} size="2x" />
          </div>
          <div className="number">1</div>
        </div>
        <strong>EVERY WEEK</strong>
        <div>
          Interacting with an encounter every week is a great way to learn new
          pathology.
        </div>
      </div>
      <div className="tutorial-block">
        <div className="icon-block">
          <div className="icon">
            <FontAwesomeIcon icon={faRepeatReg} size="2x" />
          </div>
          <div className="number">2</div>
        </div>
        <strong>EVERY 2 WEEK</strong>
        <div>
          This follow-up schedule is ideal for pathologies you are familiar
          with, but want more exposure to.
        </div>
      </div>
      <div className="tutorial-block">
        <div className="icon-block">
          <div className="icon">
            <FontAwesomeIcon icon={faRepeatReg} size="2x" />
          </div>
          <div className="number">3</div>
        </div>
        <strong>EVERY 3 WEEK</strong>
        <div>
          A great timing schedule for encounters that you want to store into
          long-term memory.
        </div>
      </div>
      <div className="tutorial-block">
        <div className="icon-block">
          <div className="icon">
            <FontAwesomeIcon icon={faRepeatReg} size="2x" />
          </div>
          <div className="number">4</div>
        </div>
        <strong>EVERY 4 WEEK</strong>
        <div>
          For encounters that you are familiar and comfortable with, where
          reminders would still be helpful.
        </div>
      </div>

      <div className="confirm" onClick={() => dismissNotification()}>
        Dismiss
      </div>
    </TutorialContainer>
  );
}
const TutorialContainer = styled.div`
  padding: 25px;
  margin-top: 35px;
  background-color: ${grayscale.lightGray};
  text-align: center;
  .text {
    font-size: 18px;
    max-width: 500px;
    margin: auto;
    margin-top: 15px;
  }
  .big-text {
    font-size: 28px;
    font-weight: 700;
    margin-top: 20px;
  }
  .confirm {
    padding: 8px 10px;
    border-radius: 5px;
    background-color: ${grayscale.gray};
    color: ${blue.bright};
    max-width: fit-content;
    margin: auto;
    margin-top: 25px;
    cursor: pointer;
    font-weight: ${fontWeight.bold};
    &:hover {
      background-color: ${grayscale.gray};
    }
  }
  .tutorial-block {
    max-width: 350px;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 25px;
    .icon-block {
      position: relative;
      .number {
        position: absolute;
        left: 172px;
        top: 8px;
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  @media (max-width: 610px) {
    .tutorial-block {
      .icon-block {
        .number {
          display: none;
        }
      }
    }
  }
  @media (max-width: 425px) {
    .tutorial-block {
      text-align: left;
      .icon-block {
        .number {
          left: 13px;
          display: block;
        }
      }
    }
  }
`;

import React from "react";

// Styles
import styled from "styled-components";

// Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

export default function Spinner(props) {
  return (
    <Spin>
      <div className="spinner" />
    </Spin>
  );
}

export function SpinnerSmall(props) {
  return (
    <Spin>
      <div className="spinner-small" />
    </Spin>
  )
}

const Spin = styled.div`
  .spinner {
    margin: auto;
    height: 28px;
    width: 28px;
    animation: rotate 0.8s infinite linear;
    border: 8px solid #333;
    border-right-color: transparent;
    border-radius: 50%;
  }
  .spinner-small {
    margin: auto;
    height: 15px;
    width: 15px;
    animation: rotate 0.8s infinite linear;
    border: 4px solid #333;
    border-right-color: transparent;
    border-radius: 50%;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

import styled from "styled-components";
import { blue } from "./Colors";
import { fontFamily } from "./Typography";

export const ButtonRegular = styled.button`
  border: none;
  padding: 10px;
  width: 100%;
  cursor: pointer;
  font-size: 16px;
  color: white;
  background: ${blue.bright};
  font-family: ${fontFamily.sansSerif};
`;

import React, { useEffect, useState } from "react";
import { url } from "../settings";

// Dependencies

//Components
import Modal from "../Modal/Modal";

// Styles
import styled from "styled-components";
import { fontFamily, fontWeight, blue, grayscale } from "../Cytoplasm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat, faTrash } from "@fortawesome/pro-light-svg-icons";

export default function Followup(props) {
  const { user, hashId, followupCycleEncounters, setFollowupCycleEncounters } =
    props;

  const [isFollowup, setIsFollowup] = useState(false);
  const [followupCycle, setFollowupCycle] = useState();

  useEffect(() => {
    if (followupCycleEncounters) {
      const foundEncounter = followupCycleEncounters.find(
        (encounter) => encounter.hash_id == hashId
      );
      if (foundEncounter) {
        setIsFollowup(true);
        setFollowupCycle(foundEncounter.cycle);
      } else {
        setIsFollowup(false);
        setFollowupCycle();
      }
    }
  }, [followupCycleEncounters]);

  const [openState, setOpenState] = useState(false);

  // let newCycle;
  function handleClick(newCycle) {
    console.log("followupCycle", followupCycle);
    console.log("newCycle", newCycle);

    fetch(`${url}/rounds/followups/`, {
      method: "post",
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify({
        userId: user.id,
        hashId: hashId,
        newCycle: newCycle,
        oldCycle: followupCycle || 0,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setFollowupCycleEncounters(result.followup_cycle_encounters);
        return;
      });
  }
  function handleClickModal() {
    setOpenState(true);
  }

  return (
    <>
      <Container onClick={handleClickModal} isFollowup={isFollowup}>
        <FontAwesomeIcon icon={faRepeat} size="2x" />
        <div className="number">{followupCycle}</div>
      </Container>
      <Modal
        setOpenState={setOpenState}
        openState={openState}
        title="Follow-up Schedule">
        <FollowupItem onClick={() => handleClick(1, followupCycle)}>
          <Block active={followupCycle == 1 && "true"}>
            <div className="followup-icon">
              <FontAwesomeIcon icon={faRepeat} size="lg" />
            </div>

            <div>
              <h5 className="followup-title">Every week</h5>
              <p className="followup-description">
                Repeat encounter every (1) week
              </p>
            </div>
          </Block>
        </FollowupItem>

        <FollowupItem onClick={() => handleClick(2, followupCycle)}>
          <Block active={followupCycle == 2 && "true"}>
            <div className="followup-icon">
              <FontAwesomeIcon icon={faRepeat} size="lg" />
            </div>

            <div>
              <h5 className="followup-title">Every other weeks</h5>
              <p className="followup-description">
                Repeat encounter every (2) week
              </p>
            </div>
          </Block>
        </FollowupItem>

        <FollowupItem onClick={() => handleClick(3, followupCycle)}>
          <Block active={followupCycle == 3 && "true"}>
            <div className="followup-icon">
              <FontAwesomeIcon icon={faRepeat} size="lg" />
            </div>

            <div>
              <h5 className="followup-title">Every three weeks</h5>
              <p className="followup-description">
                Repeat encounter every (3) week
              </p>
            </div>
          </Block>
        </FollowupItem>

        <FollowupItem onClick={() => handleClick(4, followupCycle)}>
          <Block active={followupCycle == 4 && "true"}>
            <div className="followup-icon">
              <FontAwesomeIcon icon={faRepeat} size="lg" />
            </div>

            <div>
              <h5 className="followup-title">Every four weeks</h5>
              <p className="followup-description">
                Repeat encounter every (4) week
              </p>
            </div>
          </Block>
        </FollowupItem>

        <FollowupItem onClick={() => handleClick(0, followupCycle)}>
          <Block>
            <div className="followup-icon">
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </div>

            <div>
              <h5 className="followup-title">Remove</h5>
              <p className="followup-description">
                Remove this encounter from all follow-up shcedules
              </p>
            </div>
          </Block>
        </FollowupItem>
      </Modal>
    </>
  );
}
const Container = styled.div`
  cursor: pointer;
  position: relative;
  padding: 3px;
  line-height: 0;
  border-radius: 4px;
  color: ${(props) =>
    props.isFollowup ? `${blue.bright}` : `${grayscale.lightDark}`};

  &:hover {
    background: #f2f2f2;
  }
  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: ${fontWeight.bold};
    font-size: 11px;
  }

  @media (max-width: 425px) {
    font-size: 14px;
    .number {
      font-size: 0.6rem;
    }
  }
`;
const Block = styled.div`
  background: #ffffff;

  border-radius: 5px;
  padding: 25px;
  height: fit-content;
  margin-bottom: 15px;
  border: 1px solid ${grayscale.gray};
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.05);
  box-shadow: ${(props) =>
    props.active && `0px 3px 10px rgba(0, 113, 227, 0.5)`};
  border: ${(props) => props.active && `2px solid ${blue.bright}`};
  .document-title {
    color: gray;
    font-size: 0.75em;
    margin-bottom: 15px;
  }
  @media (max-width: 768px) {
    border-radius: 0;
    padding: 18px;
  }
`;
const FollowupItem = styled.div`
  .followup-icon {
  }

  ${Block} {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    grid-gap: 25px;
    cursor: pointer;
    margin-bottom: 15px;

    padding: 15px;

    .followup-title {
      margin: 0;
      font-size: 20px;
    }
    .followup-description {
      margin: 0;
      font-size: 1em;
      line-height: 130%;
    }
  }
`;
